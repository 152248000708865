
import uiMixin from '../mixins/ui';

export default {
  name: 'MilestonesList',
  mixins: [
    uiMixin,
  ],
  computed: {
    milestones() {
      return this.isDebugger
        ? this.$store.getters['conditions/actionsPlanMilestones']
        : [];
    },
  },
};
